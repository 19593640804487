<template>
  <v-app id="inspire">
    <vue-headful :title="App_Title" />
    <component :is="layout">
      <router-view class="col-12"></router-view>
    </component>
  </v-app>
</template>

<!--<template>
  <v-app id="inspire">
    <ToolBar />
    <v-content>
      <router-view class="col-12"></router-view>
    </v-content>
  </v-app>
</template>-->
<script>
const default_layout = "loading";
// import ToolBar from "./components/layout/ToolBar";

export default {
  name: "App",

  computed: {
    App_Title() {
      return "أكاديمية رويال التعليمية";
    },
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
  data: () => ({
    //
  }),
};
</script>
