<template>
  <div>
    <v-content>
      <v-container class="fill-height align-start">
        <slot />
      </v-container>
    </v-content>
    <ToolBar />
  </div>
</template>

<script>
import ToolBar from "../components/layout/ToolBar";

export default {
  components: { ToolBar },
};
</script>

<style></style>
