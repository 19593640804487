<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      right
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
    >
      <v-list dense>
        <template v-for="item in items">
          <v-layout v-if="item.heading" :key="item.heading" row align-center>
            <v-flex xs6>
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-flex>
            <v-flex xs6 class="text-xs-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-flex>
          </v-layout>
          <v-list-item v-else :key="item.text" link router :to="item.route">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      :clipped-right="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="hidden-sm-and-down">أكاديمية رويال التعليمية</span>
      </v-toolbar-title>
      <!-- <v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="mdi-search"
        label="Search"
        class="hidden-sm-and-down"
      ></v-text-field>-->
      <v-spacer></v-spacer>
      <v-btn class="mx-2" fab dark color="warning" @click="logout">
        <v-icon dark>mdi-logout</v-icon>
      </v-btn>
      <!-- <v-btn icon>
        <v-icon>apps</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>notifications</v-icon>
      </v-btn>-->
      <v-btn icon large>
        <v-avatar size="32px" item>
          <!-- <v-img src="https://cdn.vuetifyjs.com/images/logos/logo.svg" alt="Vuetify"></v-img> -->
          <v-img src="assets/logo.png" alt="Vuetify"></v-img>
        </v-avatar>
      </v-btn>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      items: [
        {
          icon: "mdi-book-open-page-variant",
          text: "المواد",
          route: "/subjects",
        },
        {
          icon: "mdi-apps",
          text: "الشعب",
          route: "/classes",
        },
        {
          icon: "mdi-calendar-month",
          text: "الأعوام الدراسية",
          route: "/years",
        },
        {
          icon: "mdi-clipboard-list-outline",
          text: "برامج الدوام",
          route: "/programs",
        },
        {
          icon: "mdi-card-account-details-outline",
          text: "الطلاب",
          route: "/students",
        },
        {
          icon: "mdi-cash-multiple",
          text: "الأقساط",
          route: "/studentInstallments",
        },
        {
          icon: "mdi-file-multiple",
          text: "برامج المذاكرات",
          route: "/testPrograms",
        },
        {
          icon: "mdi-book-check-outline",
          text: "نتائج المذاكرات ",
          route: "/tests",
        },
        {
          icon: "mdi-account-clock",
          text: "سجل الدوام",
          route: "/attendances",
        },
        {
          icon: "mdi-message-text",
          text: "الملاحظات العامة ",
          route: "/notes",
        },
        {
          icon: "mdi-chart-bar",
          text: "الاحصائيات",
          route: "/statistics",
        },
      ],
    };
  },
  computed: {
    App_Title() {
      return this.$APP_TITLE;
    },
    App_Version() {
      return this.$APP_VERSION;
    },
  },
  methods: {
    logout() {
      this.$router.push("/logout");
    },
  },
};
</script>
