import Vue from "vue";

import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);
//const API_URL = "http://localhost:65437";

//const API_URL = "http://localhost:8090";

const API_URL = "http://www.adminapi.royaleducation.net";

const store = new Vuex.Store({
  state: {
    token: localStorage.getItem("access_token") || null,
  },
  getters: {
    loggedIn(state) {
      return state.token !== null;
    },
  },
  mutations: {
    retrieveToken(state, token) {
      state.token = token;
    },
    destroyToken(state) {
      state.token = null;
    },
  },
  actions: {
    destroyToken(context) {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + context.state.token;

      if (context.getters.loggedIn) {
        return new Promise((resolve, reject) => {
          axios
            .post(API_URL + "/api/Account/Logout")
            .then((response) => {
              localStorage.removeItem("access_token");
              context.commit("destroyToken");
              resolve(response);
            })
            .catch((error) => {
              localStorage.removeItem("access_token");
              context.commit("destroyToken");
              reject(error);
            });
        });
      }
    },
    retrieveToken(context, Credential) {
      const request = {
        url:
          API_URL +
          "/api/Account?userName=" +
          Credential.userName +
          "&password=" +
          Credential.password,
      };

      return new Promise((resolve, reject) => {
        axios(request)
          .then((response) => {
            const token = response.data.token;
            localStorage.setItem("access_token", token);
            context.commit("retrieveToken");
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
});

export default store;
