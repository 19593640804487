import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Loading from "./layouts/Loading.vue";
import Default from "./layouts/Default.vue";
import NoLayout from "./layouts/NoLayout.vue";
import vueHeadful from "vue-headful";
import "./registerServiceWorker";
import store from "./store/store";
import excel from "vue-excel-export";

Vue.config.productionTip = false;

Vue.component("loading-layout", Loading);
Vue.component("default-layout", Default);
Vue.component("no-layout-layout", NoLayout);
Vue.component("vue-headful", vueHeadful);
//Vue.component("vue-excel-export", excel);
Vue.use(excel);
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.loggedIn) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters.loggedIn) {
      next({
        name: "StudentIndex",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
