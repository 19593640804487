import Vue from "vue";
import VueRouter from "vue-router";

// import Home from "../views/Home.vue";
// import StudentIndex from "../student/StudentIndex.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../components/students/StudentIndex.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/subjects",
    name: "SubjectIndex",
    component: () => import("../components/subjects/SubjectIndex.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/students",
    name: "StudentIndex",
    component: () => import("../components/students/StudentIndex.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/studentInstallments",
    name: "StudentInstallmentIndex",
    component: () => import("../components/studentInstallments/StudentInstallmentIndex.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/classes",
    name: "ClassIndex",
    component: () => import("../components/classes/ClassIndex.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/years",
    name: "StudyYearIndex",
    component: () => import("../components/studyYears/StudyYearIndex.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/programs",
    name: "TimeProgramsIndex",
    component: () => import("../components/timePrograms/TimeProgramsIndex.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/testPrograms",
    name: "TestProgramsIndex",
    component: () => import("../components/testPrograms/TestProgramsIndex.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/attendances",
    name: "StudentAttendances",
    component: () =>
      import("../components/studentAttendances/StudentAttendances.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/tests",
    name: "TestResultsIndex",
    component: () => import("../components/testsResults/TestResultsIndex.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/notes",
    name: "Notes",
    component: () => import("../components/generalNotes/GeneralNotesIndex.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: () => import("../components/statistics/StatisticsIndex.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },
  {
    path: "/login",
    name: "LogIn",
    component: () => import("../components/auth/LogIn.vue"),
    meta: {
      requiresVisitor: true,
      layout: "no-layout",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../components/auth/Logout.vue"),
    meta: {
      requiresAuth: true,
      layout: "default",
    },
  },

  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
