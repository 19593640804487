<template>
  <div class="d-flex justify-center align-center" style="height: 100vh">
    <v-progress-circular
      indeterminate
      color="primary"
      size="200"
      width="10"
    ></v-progress-circular>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
